<template>
  <div class="maxBox">
    <div
      style="
        width: 100%;
        margin: 5px auto;
        display: flow-root;
        margin-bottom: 60px;
      "
    >
      <div style="position: sticky; top: 0; z-index: 999">
        <van-tabs
          v-model="active"
          color="#ffc002"
          @click="onClick"
          :sticky="true"
        >
          <!-- <van-tab title="全部" name="0"></van-tab> -->
          <van-tab title="待接单" name="2"></van-tab>
          <van-tab title="已接单" name="3"></van-tab>
          <van-tab title="已完成" name="4"></van-tab>
          <van-tab title="已撤销" name="9"></van-tab>
        </van-tabs>
      </div>

      <!-- <van-sidebar v-model="activeKey"
            color="#ffc002"
            style="
            width: 25%;
            float: left;
            position: fixed;
            overflow-y: scroll;
            height: 87%;
          ">
            <van-sidebar-item :title="item.Name"
              v-for="item in ServiceType"
              :key="item.id"
              @click="orderType(item.id)">
            </van-sidebar-item>
          </van-sidebar> -->
      <div style="margin: 0px auto; display: flow-root; width: 95%">
        <van-list
          :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          :offset="1"
          :immediate-check="false"
          @load="onLoad"
        >
          <div v-for="item in ServiceList" :key="item.SId" class="borderClass">
            <div
              class="van-card"
              @click="
                gotoUrl('/shop/myShop/myShopOrder/orderXq/' + item.OrderId)
              "
            >
              <div class="van-card__header">
                <a class="van-card__thumb">
                  <div class="van-image" style="width: 100%; height: 100%">
                    <img
                      :src="item.GoodsImg.split(',')[0]"
                      :onerror="
                        onErrorImg(
                          'https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'
                        )
                      "
                      class="van-image__img"
                      style="object-fit: cover; border-radius: 8px"
                    />
                  </div>
                  <!-- <div class="van-image"
                  style="width: 100%; height: 100%;"
                  v-else>
                  <img :src="item.SerImgUrl"
                   :onerror="onErrorImg('https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png')"
                    class="van-image__img"
                    style="object-fit: cover;border-radius: 8px;">
                </div> -->
                </a>
                <div class="van-card__content">
                  <div>
                    <div class="status van-multi-ellipsis--l2">
                      <!-- <span v-if="item.Status == 1"
                        style="color: #3baef5">{{item.StatusDesc}}</span>
                      <span v-if="item.Status == 2"
                        style="color: #E6A23C">{{item.StatusDesc}}</span> -->
                      <!-- <span v-if="item.Status == 3"
                        style="color: #ffc002">{{item.StatusDesc}}</span>
                      <span v-if="item.Status == 4"
                        style="color: #fc7d0a">{{item.StatusDesc}}</span>
                      <span v-if="item.Status == 5"
                        style="color: #3baef5">{{item.StatusDesc}}</span>
                      <span v-if="item.Status == 6"
                        style="color: #67c23a">{{item.StatusDesc}}</span>
                      <span v-if="item.Status == 9"
                        style="color: #e61549">{{item.StatusDesc}}</span> -->
                      <!-- <span>{{item.StatusDesc}}</span> -->
                    </div>
                    <div
                      class="van-card__desc van-ellipsis"
                      style="
                        font-weight: 600;
                        color: black;
                        font-size: 16px;
                        margin-top: 15px;
                      "
                    >
                      商品数量：{{ item.GoodsNum }}
                    </div>
                  </div>
                  <div class="van-card__bottom">
                    <div class="van-card__price">
                      <div>
                        <span
                          class="van-card__price-integer"
                          style="color: #ff7e1e; margin-bottom: 20px"
                          v-if="item.Amount == '0'"
                          >免费</span
                        >
                        <span
                          class="van-card__price-integer"
                          v-else
                          style="color: red; margin-bottom: 20px"
                          >￥{{ item.Amount }}积分</span
                        >
                      </div>
                      <div v-if="item.Status == '5'">
                        <span
                          class="van-card__price-integer"
                          style="color: red; margin-bottom: 20px"
                        >
                          <van-rate
                            v-model="item.Satisfaction"
                            :size="25"
                            color="#ffd21e"
                            void-icon="star"
                            void-color="#eee"
                            readonly
                          >
                          </van-rate>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="item.Status == '5'"
              style="background-color: #fafafa"
              @click="gotoUrl('/ylOrder/orderList/orderMy/' + item.OrderId)"
            >
              <div style="margin-left: 10px; margin-bottom: 10px">
                {{ item.EvalContent }}
              </div>
              <div v-if="item.EvalImgs != null && item.EvalImgs != ''">
                <span
                  v-for="(imgurl, index) in item.EvalImgs.split(',')"
                  :key="index"
                >
                  <img
                    :src="url + imgurl"
                    style="
                      width: 75px;
                      height: 75px;
                      margin: 10px;
                      border-radius: 8px;
                    "
                  />
                </span>
              </div>
            </div>
            <div class="buttonClass">
              <van-button
                size="small"
                class="buttonClassType"
                v-if="item.Status == '4' && item.EvaStatus == 1"
                color="#3baef5"
                plain
                type="primary"
                @click="PingJia(item.OrderId)"
              >
                评 价
              </van-button>
              <!-- <van-button
                size="small"
                v-if="item.Status == '2'"
                color="#ffc002"
                plain
                class="buttonClassType"
                type="primary"
                @click="getJieDanOrder(item)"
              >
                接 单
              </van-button>

              <van-button
                size="small"
                v-if="item.Status == '3'"
                plain
                class="buttonClassType"
                type="warning"
                @click="orderOver(item)"
              >
                结 单
              </van-button> -->
            </div>
          </div>
        </van-list>
      </div>
      <van-dialog
        v-model="arriveShow"
        title="提示"
        show-cancel-button
        :beforeClose="beforeClose"
      >
        <van-field
          v-model="dataForm.RevReason"
          label="撤单原因"
          placeholder="请输入撤单原因"
        />
      </van-dialog>
      <van-action-sheet
        v-model="orderRenshow"
        title="配送老人"
        @close="close()"
      >
        <div class="content">
          <van-radio-group
            v-model="oldManList"
            v-for="item in MyBindInfoList"
            :key="item.BindId"
            style="
              margin: 10px;
              padding: 15px 10px;
              background: #f6f7f8;
              border-radius: 5px;
            "
          >
            <van-radio
              :name="item.BindId"
              checked-color="#ffc002"
              style="padding: 20rpx"
            >
              <div
                style="
                  font-size: 18px;
                  font-weight: 600;
                  margin-bottom: 10rpx;
                  margin: 5px;
                "
              >
                {{ item.OMName }}
                <span style="font-weight: 500">{{ item.OMMobile }}</span>
                <span style="padding-left: 20px" @click="updata(item)">
                  <van-icon name="edit"
                /></span>
                <span style="padding-left: 20px" @click="addOldMan()">
                  <van-icon name="add-o"
                /></span>
              </div>
              <!-- <div style="font-size: 14px;margin-bottom: 10rpx;">{{item.Summary}}</div> -->
              <div style="margin: 5px">{{ item.OMAddr }}</div>
            </van-radio>
          </van-radio-group>
          <div style="">
            <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
              <van-button
                round
                type="primary"
                size="normal"
                style="font-size: 16px; width: 100%; float: none"
                color="black"
                @click="addOrder()"
              >
                确定
              </van-button>
            </div>
          </div>
        </div>
      </van-action-sheet>

      <van-action-sheet
        v-model="orderRenAddshow"
        title="配送老人信息"
        @close="close()"
      >
        <div class="content">
          <van-field
            v-model="oldmanForm.OMIdCard"
            class="shuru"
            name="oldmanForm.OMIdCard"
            label="身份证号"
            placeholder="请输入身份证号"
          />
          <van-field
            v-model="oldmanForm.OMMobile"
            class="shuru"
            name="oldmanForm.OMMobile"
            label="联系电话"
            placeholder="请输入老人联系电话"
          />
          <van-field
            v-model="oldmanForm.OMAddr"
            type="textarea"
            rows="1"
            autosize
            class="shuru"
            name="oldmanForm.OMAddr"
            label="居住地址"
            placeholder="请输入老人现居住地址"
          />
          <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
            <van-button round block type="info" color="black" @click="submit()"
              >保存</van-button
            >
          </div>
        </div>
      </van-action-sheet>
      <van-empty v-if="orderListShow" />
    </div>
  </div>
</template>

<script>
import {
  WeGetMyOrderPage,
  WxRecviceOrder,
  WxFinishOrder,
} from "@/api/RealInfo.js";
import { setOpenId, getOpenId, removeOrderId } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
Vue.use(Dialog);
Vue.use(Toast);
export default {
  data() {
    return {
      ServiceList: [],
      active: 0,
      activeKey: 0,
      activeTab: 1,
      show: false,
      orderListShow: false,
      arriveShow: false,
      status: 2,
      ServiceType: [
        { Name: "助餐", id: "1" },
        { Name: "助洁", id: "2" },
        { Name: "助行", id: "3" },
        { Name: "助浴", id: "4" },
        { Name: "助医", id: "5" },
        { Name: "康复", id: "6" },
        { Name: "巡访关爱", id: "7" },
        { Name: "其他", id: "99" },
      ],
      SerImg: [],
      EvalImgs: [],
      url: "http://lycaljdapi.dexian.ren/",
      dataForm: {
        RevReason: "",
      },
      oid: "",
      oldManList: "",
      MyBindInfoList: [],
      orderRenshow: false,
      orderRenAddshow: false,
      oldmanForm: {
        OMIdCard: "",
        OMMobile: "",
        OMAddr: "",
        BindId: undefined,
      },
      listfrom: {
        page: 1,
        limit: 10,
        status: 2,
      },
      orId: "",
      hidden: true, //没有更多数据了
      loading: true, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getServiceList();
    console.log(this.$route.params);
  },
  methods: {
    // 底部导航栏切换
    onChangeTab: function (row) {
      console.log(row);
      if (row == "0") {
        this.$router.push({
          path: "/ylOrder",
        });
      }
    },
    onErrorImg: function (imgSrc) {
      return "this.οnerrοr=null;this.src=" + '"' + imgSrc + '";';
    },
    onLoad() {
      this.listfrom.page = this.listfrom.page + 1;
      this.loading = true;
      this.getServiceList();
    },
    onClick: function (name, title) {
      console.log(name);
      this.status = name;
      this.listfrom.page = 1;
      this.ServiceList = [];
      this.finished = false;
      this.loading = true;
      if (this.status == "6") {
        this.status = "9";
        this.getServiceList();
      } else {
        this.getServiceList();
      }
      // else if (this.oType == '2') {
      //   this.oSerKind = undefined
      //   this.getServiceList();
      // }
    },

    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 服务项目类型
    orderType: function (row) {
      console.log(row);
      this.oSerKind = row;
      this.getServiceList();
    },
    getServiceList: function () {
      this.listfrom.status = this.status;
      this.listfrom.openID = getOpenId();
      setTimeout(() => {
        WeGetMyOrderPage(this.listfrom).then((res) => {
          console.log(res);
          this.loading = false;
          if (res.data.code == "0") {
            let rows = res.data.data; //请求返回当页的列表
            // 加载状态结束
            if (res.data.code == 0) {
              if (rows == null || rows.length === 0) {
                // 加载结束
                console.log("结束");
                this.finished = true;
                return;
              }
              // 将新数据与老数据进行合并
              this.ServiceList = this.ServiceList.concat(rows);
              //如果列表数据条数>=总条数，不再触发滚动加载
              if (this.ServiceList.length >= res.data.count) {
                this.finished = true;
              }
            }
            // if (res.data.data.length != '0') {
            //   this.ServiceList = res.data.data;
            //   this.orderListShow = false
            // } else {
            //   // Toast.success('暂未下单');
            //   this.ServiceList = []
            //   this.orderListShow = true
            // }
          } else {
            Toast.fail(res.data.msg);
            this.finished = true;
          }
        });
      }, 500);
    },
    // 接单
    getJieDanOrder: function (row) {
      console.log(row);
      Dialog.confirm({
        title: "提示",
        message: "确定要接单吗",
      })
        .then(() => {
          // 确认
          this.getJieDanGoods(row);
        })
        .catch(() => {
          // 取消
          console.log(111);
        });
    },
    // 接单
    getJieDanGoods: function (row) {
      console.log(row);
      WxRecviceOrder({
        openID: getOpenId(),
        OrderId: row.OrderId,
      }).then((res) => {
        if (res.data.code == 0) {
          Toast.success("接单成功");
          this.ServiceList = [];
          this.getServiceList();
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 接单
    orderOver: function (row) {
      console.log(row);
      Dialog.confirm({
        title: "提示",
        message: "是否完成结单",
      })
        .then(() => {
          // 确认
          console.log(123);
          this.getGoodsOver(row);
        })
        .catch(() => {
          // 取消
          console.log(111);
        });
    },
    //结单
    getGoodsOver: function (row) {
      console.log(row);
      WxFinishOrder({
        openID: getOpenId(),
        OrderId: row.OrderId,
      }).then((res) => {
        if (res.data.code == 0) {
          Toast.success("结单成功");
          (this.active = 2), (this.ServiceList = []);
          this.getServiceList();
        }
      });
    },
    // 确认结单
    // orderOver (row) {
    //   Dialog.confirm({
    //     title: "提示",
    //     message: "是否完成结单",
    //   })
    //     .then(() => {
    //       WxFinishOrder({
    //         openID: getOpenId(),
    //         OrderId: row.OrderId,
    //       }).then((res) => {
    //         if (res.data.code == 0) {
    //           Toast.success("结单成功!");
    //           this.active = 1,
    //             this.getServiceList()
    //         } else {
    //           Toast.fail(res.data.msg);
    //         }
    //       });
    //     })
    //     .catch(() => {
    //     });
    // },
    // 撤单
    getRevokeOrder: function (row) {
      this.arriveShow = true;
      console.log(row.OrderId);
      this.oid = row.OrderId;
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        setTimeout(done, 1000);
        WeRevokeOrder({
          OpenID: getOpenId(),
          OrderId: this.oid,
          RevReason: this.dataForm.RevReason,
        }).then((res) => {
          if (res.data.code == 0) {
            Toast.success("撤单成功");
            this.ServiceList = [];
            this.getServiceList();
          }
        });
      } else if (action === "cancel") {
        done(); //关闭
      }
    },
    PingJia(orderId) {
      console.log(orderId); // 订单id
      this.$router.push({
        name: "PingJia",
        query: { orderId: orderId },
      });
    },
    // 删除订单
    delet: function (row) {
      console.log(row.OrderId);
      WeDeleteOrder({ OpenID: getOpenId(), OrderId: row.OrderId }).then(
        (res) => {
          if (res.data.code == 0) {
            Toast.success("删除成功");
            this.ServiceList = [];
            this.getServiceList();
            removeOrderId();
          } else {
            Toast.fail(res.data.msg);
          }
        }
      );
    },
    //下单
    addOrder: function () {
      // Dialog.confirm({
      //   title: "提示",
      //   message: "确定要下单吗!",
      // })
      //   .then(() => {
      WePlaceOrder({
        OpenID: getOpenId(),
        OrderId: this.orId,
        BindId: this.oldManList,
      }).then((res) => {
        if (res.data.code == "0") {
          Toast.success("下单成功");
          this.ServiceList = [];
          this.getServiceList();
          this.showPopupShow = false;
          this.orderRenshow = false;
        } else {
          Toast.fail(res.data.msg);
        }
      });
      // })
    },
    // 评价
    appraise: function (row) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + row,
        });
      } else {
        this.$router.push(row);
      }
      // this.$router.push("/ylOrder/orderList/appraise/" + row.OrderId);
    },
    close: function () {
      // console.log(123123);
      this.oldManList = "";
      this.oldmanForm.OMIdCard = "";
      this.oldmanForm.OMMobile = "";
      this.oldmanForm.OMAddr = "";
    },
    add: function (row) {
      console.log(row);
    },
    // 下单人
    orderRen: function (row) {
      console.log(row);
      this.orId = row;
      this.orderRenshow = true;
      this.getMyBindInfoPage();
    },
    getMyBindInfoPage: function () {
      WeGetMyBindInfoPage({
        openID: getOpenId(),
        page: "1",
        limit: "999",
      }).then((res) => {
        if (res.data.code == "0") {
          if (res.data.data.length != "0") {
            this.MyBindInfoList = res.data.data;
            // this.orderListShow = false
          } else {
            // Toast.fail('未绑定老人信息');
            this.MyBindInfoList = [];
            this.orderRenshow = false;
            this.orderRenAddshow = true;
            // this.orderListShow = true
          }
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 添加老人信息
    submit() {
      console.log(this.oldmanForm);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(this.oldmanForm.OMIdCard)) {
        Toast.fail("身份证号码不正确");
      } else if (this.oldmanForm.OMMobile == "") {
        Toast.fail("请输入老人联系电话");
      } else if (this.oldmanForm.OMAddr == "") {
        Toast.fail("请输入老人现居住地址");
      } else {
        WeSaveBindInfo({
          OpenID: getOpenId(),
          OMIdCard: this.oldmanForm.OMIdCard,
          OMMobile: this.oldmanForm.OMMobile,
          OMAddr: this.oldmanForm.OMAddr,
          BindId: this.oldmanForm.BindId,
        }).then((res) => {
          if (res.data.code == "0") {
            Toast.success("绑定成功");
            this.orderRenAddshow = false;
            this.orderRenshow = true;
            this.oldmanForm.OMIdCard = "";
            this.oldmanForm.OMMobile = "";
            this.oldmanForm.OMAddr = "";
            this.oldmanForm.BindId = undefined;
            this.getMyBindInfoPage();
          } else {
            Toast.fail(res.data.msg);
          }
        });
      }
    },
    addOldMan: function () {
      this.orderRenAddshow = true;
    },
    // 编辑老人信息
    updata: function (row) {
      this.orderRenAddshow = true;
      console.log(row);
      this.oldmanForm = Object.assign({}, row);
    },
  },
};
</script>

<style scoped>
.maxBox {
  background-color: #f9f9f9;
  min-height: 100%;
}
.borderClass {
  /* box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
  border-radius: 5px; */
}
.van-card {
  background-color: white;
  border-radius: 5px;
  margin-top: 8px;
}
/* .van-button__content {
  float: left !important;
} */
.van-button__text {
  display: inline-grid !important;
}
.van-action-sheet {
  height: 500px !important;
}
.van-action-sheet__content {
  margin-bottom: 70px !important;
}
.van-sidebar-item--select::before {
  background-color: #ffc002 !important;
}
.title {
  margin: 10px 0;
  font-size: 17px;
  font-weight: 600;
  width: 70%;
  float: left;
}
.status {
  margin: 11px 0;
  font-size: 16px;
  width: 25%;
  float: right;
  text-align: center;
  color: #646566;
}
.buttonClass {
  width: 100%;
  text-align: right;
  margin-right: 10px;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.buttonClassType {
  margin: 5px;
  padding: 0 15px;
  border-radius: 5px;
  font-size: 16px;
}
</style>